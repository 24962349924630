<template>
  <div>{{ time }}</div>
</template>
<script>
import { Digit, EMPTY_STRING, TIME, COLON } from '@/constants'
import { requestInterval } from '@/helpers'

const NOUGHT = String(Digit.NOUGHT)

export default {
  name: TIME,
  data: () => ({
    time: EMPTY_STRING,
    timeInterval: null,
  }),
  methods: {
    runTime() {
      const date = new Date()
      const hours = (NOUGHT + date.getHours()).substr(-Digit.TWO)
      const minutes = (NOUGHT + date.getMinutes()).substr(-Digit.TWO)

      this.time = hours + COLON + minutes
    },
  },
  mounted() {
    this.timeInterval = requestInterval(this.runTime, Digit.FIVE_HUNDRED)
  },
  beforeDestroy() {
    this.timeInterval.clear()
  },
}
</script>
